import { Paragraph } from '@components/ui/Paragraph/Paragraph';
import { useSiteInfo } from '@context/siteInfoContext';
import { useEffect, useState } from 'react';
import type { Product, Variant } from 'types';

import { cn } from '@/lib/utils';
import { getGooglePriceForVariant } from '@/utils/googlePricing';
import { formatPrice } from '@/utils/priceUtils';

export default function Price({
    className,
    product,
    locale,
}: {
    className?: string;
    product?: Product | Variant;
    locale?: string;
}) {
    const { dictionary: globalDictionary } = useSiteInfo();

    const [GADPrice, setGADPrice] = useState<number>();

    useEffect(() => {
        if (product?.type === 'Variant') {
            const personalPrice = getGooglePriceForVariant(product.id);
            if (personalPrice) setGADPrice(personalPrice);
            return () => setGADPrice(undefined);
        }
    }, [product?.type, product?.id]);

    if (!product) return null;

    const isVariant = product.type == 'Variant';

    if (isVariant && typeof GADPrice === 'number') {
        return (
            <Paragraph className={cn('flex gap-x-1', className)}>
                <span className="font-medium">
                    {formatPrice(GADPrice, locale)}
                </span>
                <span className="text-grey-300 line-through">
                    {formatPrice(
                        product.rrpWhenCampaign ?? product.originalPrice ?? 0,
                        locale,
                    )}
                </span>
            </Paragraph>
        );
    }

    return (
        <Paragraph className={cn('flex gap-x-1', className)}>
            {product.priceType === 'standard' ? (
                <span className="font-medium">
                    {product.fromPrice && globalDictionary.from}{' '}
                    {formatPrice(
                        isVariant
                            ? (product.price ?? 0)
                            : (product.presentationPrice ?? 0),
                        locale,
                    )}
                </span>
            ) : (
                <>
                    <span className="font-medium">
                        {product.fromPrice && globalDictionary.from}{' '}
                        {formatPrice(
                            isVariant
                                ? (product.price ?? 0)
                                : (product.presentationPrice ?? 0),
                            locale,
                        )}
                    </span>
                    <span className="text-grey-300 line-through">
                        {formatPrice(
                            isVariant
                                ? (product.rrpWhenCampaign ??
                                      product.originalPrice ??
                                      0)
                                : (product.presentationRrpWhenCampaign ??
                                      product.presentationOriginal ??
                                      0),
                            locale,
                        )}
                    </span>
                </>
            )}
        </Paragraph>
    );
}
